/* eslint-disable indent */
import React from 'react'
import styles from './project-hero.module.css'

// List item to render a project
const ProjectHero = ({
  title,
  company,
  role,
  date,
  pressUrl,
  enablePressUrl,
  appIconPath,
  previewImagePath,
  index,
}) => {
  const summary = (
    <div className="grid-span-5 grid-small-span-12">
      <div className={`${styles.projectSummary} ${index % 2 == 0 ? styles.projectSummaryEven : styles.projectSummaryOdd}`}>
        <img
          className={styles.projectSummaryAppIcon}
          src={appIconPath}
        />
        <div className={styles.projectSummaryCompany}>
          <small>{company}</small>
        </div>
        <div className={styles.projectSummaryTitle}>
          {title}
        </div>
        {/* Hiding the role because it's the same for each project "Product Designer"
        <div className={styles.projectSummaryRole}>
          {role}
        </div> */}
        {enablePressUrl && pressUrl && (
          <div className={styles.projectSummaryPressLink}>
            <a
              href={pressUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Read press
            </a>
          </div>
        )}
      </div>
    </div>
  )

  const image = (
    <div className="grid-span-5 grid-small-span-12">
      <div className={styles.projectSummaryImageContainer}>
        <img
          className={styles.projectSummaryImageFrame}
          src={require('media/device-frames/apple-iphone-xs-gold.png')}
        />
        <img
          className={styles.projectSummaryImageSource}
          src={previewImagePath}
        />
      </div>
    </div>
  )

  return (
    <li className={styles.project}>
      <div className="grid">
        {index % 2 == 0 ? (
          <>
            {image}
            {summary}
          </>
        ) : (
            <>
              {summary}
              {image}
            </>
          )}
      </div>
    </li >
  )
}

export default ProjectHero
