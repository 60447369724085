/* eslint-disable react/display-name */
import React from 'react'
import Site from 'components/site'
import Page from 'components/page'
import PageHeader from 'components/page/page-header'
import ProjectsTable from 'components/projects/projects-table'
import ProjectHero from 'components/projects/project-hero'
import useSite from 'hooks/use-site'
import useExperience from 'hooks/use-experience'
import useOpenSourceProjects from 'hooks/use-open-source-projects'
import useProjects from 'hooks/use-static-projects'
import styles from './projects.module.css'

const Projects = () => {
  const { siteMetadata } = useSite()

  const {
    enablePortfolioProjects,
    enableOpenSourceProjects,
    enableExperienceList,
  } = siteMetadata.internalSettings

  return (
    <Site>
      <Page title="Work" path="projects" layout={false}>
        <PageHeader
          title="One pixel at a time"
          subtitleAfter={(
            <p>
              As a designer, I create useful, delightful products products for people. Currently, my work is focused on privacy & safety at <a href="https://messenger.com/privacy" target="_blank" rel="noopener noreferrer">Messenger</a> by Facebook.
            </p>
          )}
        />
        {enablePortfolioProjects && <ProjectsSection />}
        {enableOpenSourceProjects && <OpenSourceSection />}
        {enableExperienceList && <ExperienceSection />}
      </Page>
    </Site>
  )
}

/**
 * Renders list of projects with image previews
 */
const ProjectsSection = () => {
  const projects = useProjects()

  return (
    <section>
      <ul>
        {projects.map((project, i) => (
          <ProjectHero
            key={i}
            index={i}
            title={project.title}
            company={project.company}
            role={project.role}
            date={project.date}
            pressUrl={project.pressUrl}
            previewImagePath={project.previewImagePath}
            appIconPath={project.appIconPath}
            enablePressUrl={false}
          />
        ))}
      </ul>
    </section>
  )
}


/**
 * Renders table of open source projects with links to GitHub and link to npm
 */
const OpenSourceSection = () => {
  const { siteMetadata } = useSite()
  const openSourceProjects = useOpenSourceProjects()

  return (
    <section className={`${styles.workSection} ${styles.workSectionOpenSource}`}>
      <div className="grid">
        <div className="grid-span-12">
          <h2>Open Source Projects</h2>
          <ProjectsTable
            rows={openSourceProjects}
            mapRows={(row) => {
              return [{
                key: 'Title',
                value: row.title
              }, {
                key: 'Downloads',
                value: row.downloads,
                template: (v) => (<small>&gt;{v} npm installs</small>)
              }, {
                key: 'Link',
                value: row.link,
                template: (v) => (
                  <small>
                    <a href={v} className={styles.externalLink} target="_blank" rel="noopener noreferrer">
                      GitHub
                      </a>
                  </small>
                )
              }]
            }}
          />
          <small className={styles.workSectionTableFootnote}>
            <span>Sourced Feb. 2020</span>
            <span>&nbsp;&middot;&nbsp;</span>
            <a
              href="https://npmjs.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              npm
              </a>
          </small>
        </div>
      </div>
    </section >
  )
}


/**
 * Renders table of work experience with link to LinkedIn
 */
const ExperienceSection = () => {
  const { siteMetadata } = useSite()
  const experience = useExperience()

  return (
    <section className={`${styles.workSection} ${styles.workSectionExperience}`}>
      <div className="grid">
        <div className="grid-span-12 grid-small-span-12">
          <h2>Experience</h2>
          <ProjectsTable
            rows={experience}
            mapRows={(row) => {
              const { date, startDate, endDate } = row

              return [{
                key: 'Company',
                value: row.company
              }, {
                key: 'Role',
                value: row.role,
              }, {
                key: 'Date',
                value: { date, startDate, endDate },
                template: (v) => v.date ? (<small>{v.date}</small>) : (
                  <small>{v.startDate}&#8211;{v.endDate}</small>
                )
              }]
            }}
          />
          <small className={styles.workSectionTableFootnote}>
            <a
              href={siteMetadata.linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
              </a>
          </small>
        </div>
      </div>
    </section>
  )
}

export default Projects
