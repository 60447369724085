/* eslint-disable react/display-name */
import React from 'react'
import styles from './projects-table.module.css'

const Table = ({ rows, mapRows }) => {
  return (
    <ul className={styles.table}>
      {rows.map((row, i) => (
        <TableRow
          key={i}
          columns={mapRows(row)}
        />
      ))}
    </ul>
  )
}

const TableRow = ({ columns }) => {
  return (
    <li className={styles.tableRow}>
      {columns.map(({ key, value, template }) => (
        <div
          key={key}
          className={styles.tableColumn}
        >
          {template ? template(value) : value}
        </div>
      ))}
    </li>
  )
}

export default Table
