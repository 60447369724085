const useOpenSourceProjects = () => {
  return [{
    title: 'Redux Promise',
    link: 'https://github.com/pburtchaell/redux-promise-middleware',
    downloads: '7,322,000',
  }, {
    title: 'React Notification',
    link: 'https://github.com/pburtchaell/react-notification',
    downloads: '1,013,000',
  }, {
    title: 'Shader',
    link: 'https://github.com/pburtchaell/shader',
    downloads: '485,644',
  }, {
    title: 'React Input',
    link: 'https://github.com/pburtchaell/react-input',
    downloads: '20,089',
  }]
}

export default useOpenSourceProjects
