const useExperience = () => {
  return [{
    company: 'Fixt',
    role: 'Junior Software Developer',
    location: 'Remote',
    startDate: '2014',
    endDate: '2015',
  }, {
    company: 'Lawn Chair',
    role: 'Co-Founder',
    location: 'New Orleans',
    startDate: '2015',
    endDate: '2018',
  }, {
    company: 'Revelry',
    role: 'Software Engineer, Contract',
    location: 'New Orleans',
    startDate: '2017',
    endDate: '2018',
  }, {
    company: null,
    role: 'Product Design Intern',
    location: 'Menlo Park, CA',
    date: '2016',
  }, {
    company: null,
    role: 'Product Design Intern',
    location: 'Menlo Park, CA',
    date: '2017',
  }, {
    company: 'Facebook',
    role: 'Product Designer',
    location: 'Menlo Park, CA',
    startDate: '2018',
    endDate: 'Present'
  }].reverse()
}

export default useExperience
