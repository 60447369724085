const useStaticProjects = () => {
  return [{
    title: 'Vanish Mode',
    company: 'Messenger',
    role: 'Product Design Lead',
    date: '2020',
    previewImagePath: require('media/projects/messenger-vanish-mode.png'),
    appIconPath: require('media/app-icons/blurple.png'),
  }, {
    title: 'Privacy Settings',
    company: 'Messenger',
    role: 'Product Design Lead',
    date: '2020',
    previewImagePath: require('media/projects/messenger-privacy-settings.png'),
    appIconPath: require('media/app-icons/blurple.png'),
  }, {
    title: 'Secret Conversations',
    company: 'Messenger',
    role: 'Product Designer',
    date: '2020',
    previewImagePath: require('media/projects/messenger-secret.png'),
    appIconPath: require('media/app-icons/blurple.png'),
  }, {
    title: 'Groups',
    company: 'Messenger',
    role: 'Product Designer',
    date: '2018',
    pressUrl: 'https://techcrunch.com/2018/10/11/facebook-groups-chat/',
    previewImagePath: require('media/projects/messenger-groups.png'),
    appIconPath: require('media/app-icons/blurple.png'),
  }, {
    title: 'Weather',
    company: 'Facebook',
    role: 'Product Design Intern',
    date: '2016',
    pressUrl: 'https://techcrunch.com/2017/02/08/facebook-can-now-replace-your-weather-app/',
    previewImagePath: require('media/projects/facebook-weather.png'),
    appIconPath: require('media/app-icons/facebook.png'),
  }]
}

export default useStaticProjects
